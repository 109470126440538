import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { useRef, useState } from 'react'

const donutAngle = Math.PI * 0.3

export default function Donut(props){
    const [ screenWidth, setScreenWidth ] = useState(0)

    const donutRef = useRef()
    const groupDonutRef = useRef()

    const donutModel = useGLTF('./donutPink.glb', true)

    useFrame((state, delta) => {
        setScreenWidth(state.size.width)
        donutRef.current.rotation.y += delta
    })

    return <group ref={ groupDonutRef } {...props}>
        <primitive
            ref={ donutRef }
            object={ donutModel.scene }
            position={ [ ((screenWidth <= 700) ? 0 : -2), ((screenWidth <= 700) ? -0.5 : 0), 0 ] }
            rotation={ [ donutAngle, 0, 0 ] }
        />
    </group>
}

useGLTF.preload('./donutPink.glb')