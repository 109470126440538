import { Canvas } from '@react-three/fiber'
import Experience from './Experience.jsx'

export default function MainScene(){  
    return <>
        <Canvas
            shadows
            camera={ {
                fov: 45,
                near: 0.1,
                far: 200,
                position: [ -2, 0, 6 ]
            } }
        >
            <Experience />
        </Canvas>
    </>
}