import { OrbitControls, useProgress } from '@react-three/drei'
import Lights from './Lights.jsx'
import Walls from './components/Walls.jsx'
import Donut from './components/Donut.jsx'
import { useFrame } from '@react-three/fiber'
import { Suspense } from 'react'
import { Perf } from 'r3f-perf'

export default function Experience()
{
    const { progress } = useProgress()

    useFrame(() => {
        if(progress == 100){
            document.getElementsByClassName('overlay')[0].classList.add('hide')
        }
    })

    return <>
        <color args={ [ '#F089C3' ] } attach={ 'background' } />

        { (location.hash == "#isa") && <Perf /> }

        { (location.hash == "#isa") && <OrbitControls makeDefault /> }

        <Lights />

        <Suspense>
            <Donut />
            <Walls />
        </Suspense>

    </>
}