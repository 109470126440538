export default function Walls(){
    return <group>
        <mesh
            position-y={ -2 }
            scale={ [ 10, 0.2, 10 ] }
        >
            <boxGeometry />
            <meshStandardMaterial color={ '#F089C3' } />
        </mesh>

        <mesh
            position={ [ 0, 2, -5 ] }
            rotation-x={ Math.PI * 0.5 }
            scale={ [ 10, 0.2, 8 ] }
        >
            <boxGeometry />
            <meshStandardMaterial color={ '#F089C3' } />
        </mesh>

        <mesh
            position={ [ -5, 2, 0 ] }
            rotation={ [ Math.PI * 0.5, 0, Math.PI * 0.5 ] }
            scale={ [ 10, 0.2, 8 ] }
        >
            <boxGeometry />
            <meshStandardMaterial color={ '#F089C3' } />
        </mesh>

        <mesh
            position={ [ 5, 2, 0 ] }
            rotation={ [ Math.PI * 0.5, 0, Math.PI * 0.5 ] }
            scale={ [ 10, 0.2, 8 ] }
        >
            <boxGeometry />
            <meshStandardMaterial color={ '#F089C3' } />
        </mesh>
    </group>
}