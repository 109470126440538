import './style.css'
import ReactDOM from 'react-dom/client'
import MainScene from './mainScene.jsx'
import Interface from './Interface.jsx'

const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(<>
    <MainScene />
    <Interface />
</>)