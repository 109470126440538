export default function Interface(){
    return <>
        <div className="overlay"></div>
        <div className="poster">
            <header>
                <h1>Daniel Síla</h1>
            </header>

            <main>
                <p>Hi. I am creative developer and this is my portfolio.</p>
            </main>

            <footer>
                <p>&copy; Daniel Síla. All rights reserved.</p>
            </footer>
        </div>
    </>
}